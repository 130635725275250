const contracts = {
  moni: {
    1000: "0xb12Ba3134d569a6961429511dCa6bEa0755CA39E",
    1001: "0x7D2ea85F1dF98201289598E20778529f98E0d2Ba",
  },
  stt: {
    1000: "0xa5cee110eF72333E44D8aeb882B07aAB0F7175F4",
    1001: "0xae8b6389A951eF58E997bbBB886E721be5F33a0D",
  },
  moniStaking: {
    1000: "0x007EA4F3D6BF5E80CD8C347dB8435EAD7BDd16Ef",
    1001: "0x8f82946d603625F4beA35ee3fFF6Bb347a24778e",
  },
  sttCollector: {
    1000: "0xBa13f3528D55E715Cd4f83e634E0ccA5979f18d1",
    1001: "0xdE596D2e9A7fEb9b3801Da2823A8a16D3671B300",
  }
};

export default contracts;
