import React, { useEffect, useState } from "react";
import "./index.less";

type Props = {
  destDate: number;
  isSale: boolean;
  onEnd: () => void;
};

const Counter: React.FC<Props> = ({destDate, onEnd, isSale}) => {
  const [timer, setTimer] = useState(null);
  const [counter, setCounter] = useState(0);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);

 

  useEffect(() => {
    if (destDate > 0 && !timer) {
      setCounter(Math.floor((new Date()).getTime()/1000));
      const interval = setInterval(() => {
        setCounter(Math.floor((new Date()).getTime()/1000));
      }, 1000);
      setTimer(interval);
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destDate]);

  useEffect(() => {
    const calculate = () => {
      let seconds = destDate - counter;
      const day = Math.floor(seconds / 3600 / 24);
      seconds = seconds - day * 3600 * 24;
      const hour = Math.floor(seconds / 3600);
      seconds -= hour * 3600;
      const minute = Math.floor(seconds / 60);
      seconds -= minute * 60;
      setDay(day);
      setHour(hour);
      setMinute(minute);
      setSecond(seconds);
    };

    const setCountDownOnEnd = () => {
      setDay(0);
      setHour(0);
      setMinute(0);
      setSecond(0);
    }
  
    if (counter > 0 && counter > destDate) {
      setCountDownOnEnd();
      onEnd();
      return () => clearInterval(timer);
    }
    calculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (<div className="counter">
    <div className={"body " + (isSale ? "sale" : "stake")}>
      <div className="box">
        <div className="title">{isSale ? 'SALE OPEN' : 'STAKING OPEN'}</div>
        <div className="detail">
          <span className="number">{day}</span>
          <span className="unit">DAY</span>
        </div>
      </div>
      <div className="box">
        <div className="detail">
          <span className="number">{hour}</span>
          <span className="unit">HR</span>
        </div>
      </div>
      <div className="box">
        <div className="detail">
          <span className="number">{minute}</span>
          <span className="unit">MIN</span>
        </div>
      </div>
      <div className="box">
        <div className="detail">
          <span className="number">{second}</span>
          <span className="unit">SC</span>
        </div>
      </div>
    </div>
  </div>);
};

export default Counter;