import { useEffect } from "react";
import { Layout, Button } from "antd";
import imgLogo from "../../assets/images/launchpad_logo.png";
import imgCongrat from "../../assets/images/congrat.png";
import { useMonstaAuth } from "../../hooks/useMonstaAuth";
import "./index.less";

const { Header } = Layout;

const AlertBar = ({amount}) => (
  <div className="alert-bar">
    <img src={imgCongrat} alt='congrat' />
    Congratulations! You have purchased {amount} $LOA
  </div>
);

export default function AppHeader({ purchased, amount }) {
  const { active, connect, disconnect, refresh } = useMonstaAuth();

  useEffect(() => {
    refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="header-wrapper">
      { purchased &&
        <AlertBar amount={amount} />
      }
      <Header className="header">
        <a href="/" className="logo">
          <img src={imgLogo} alt="logo" />
        </a>
        <Button className="btn-connect" onClick={active ? disconnect : connect}>
          {active ? "Disconnect" : "Connect Wallet"}
        </Button>
      </Header>
    </div>
  );
};
