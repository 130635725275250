import addresses from "../config/contracts";
import { Address } from "../config/types";

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID || 1001;
  return address[chainId] ? address[chainId] : address[1001];
};

export const getMoniAddress = () => {
  return getAddress(addresses.moni);
};
export const getSTTAddress = (): string => {
  return getAddress(addresses.stt);
};

export const getMoniStakingAddress = () => {
  return getAddress(addresses.moniStaking);
};

export const getSttCollectorAddress = () => {
  return getAddress(addresses.sttCollector);
};
