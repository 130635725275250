import fetchGraphQL from "./fetchGraphQL";

type ResponseType = {
  id: string;
  amount: string;
  sender: string;
}

const fetchPayment = async (sender: string, first: number = 1): Promise<ResponseType | null> => {
  try {
    const response = await fetchGraphQL(
      "launchpad",
      `
      query Payments($first: Int, $sender: BigInt!) {
        payments(
          first: $first, 
          where: { sender: $sender }
        ) {
          id
          amount
          sender
        }
      }
      `,
      {
        sender,
        first,
      }
    );

    return response.data.payments[0];
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default fetchPayment;
