import React from 'react';
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "./services/web3";
import { History, HistoryContext, LocationContext } from "./config/history";
import { RefreshContextProvider } from './contexts/refreshContext';
import Main from './pages/main';
import './App.css';

type AppProps = {
  history: History;
};

const App:React.FC<AppProps> = ({history}) => {
  const location = history.location;
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <HistoryContext.Provider value={history}>
        <LocationContext.Provider value={location}>
          <RefreshContextProvider>
            <Main />
          </RefreshContextProvider>
        </LocationContext.Provider>
      </HistoryContext.Provider>
    </Web3ReactProvider>
  );
}

export default App;
