export const config = {
    // Core application settings
    app: {
      name: "Monsta Infinite Marketplace",
      origin: process.env.REACT_APP_ORIGIN,
      env: process.env.NODE_ENV,
      REACT_APP_NODE_1: process.env.REACT_APP_NODE_1,
      REACT_APP_CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
      monstaGraphQL: process.env.REACT_APP_MONSTA_GRAPHQL
    },
  };
  