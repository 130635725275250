import React from 'react';
import MonstaModal, { ModalProps } from '../Modal';
import './style.less';

const PlaceBetModal: React.FC<ModalProps> = (props) => {
    return (
        <MonstaModal {...props}
            icon={
                <span className='question'><svg width="66" height="79" viewBox="0 0 66 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M45.6802 55.7208L50.4732 68.8897L37.0478 73.7761L32.2548 60.6073L45.6802 55.7208ZM8.21647 37.3992C7.15302 34.3001 6.639 31.2914 6.67443 28.3733C6.76686 25.4345 7.35433 22.7028 8.43686 20.1783C9.57639 17.6331 11.2136 15.3909 13.3485 13.4518C15.5196 11.4349 18.1729 9.85589 21.3083 8.71468C25.3559 7.24149 28.9268 6.58736 32.0212 6.75231C35.1518 6.8395 37.8446 7.40884 40.0996 8.46035C42.3545 9.51186 44.1768 10.8822 45.5664 12.5715C46.9922 14.1831 48.006 15.8154 48.6077 17.4687C49.6037 20.2051 50.0527 22.5917 49.9548 24.6287C49.914 26.645 49.5802 28.4773 48.9535 30.1257C48.3839 31.7534 47.6094 33.2619 46.6302 34.6512C45.6873 35.9628 44.8117 37.2822 44.0036 38.6093C43.1746 39.8794 42.5116 41.2505 42.0145 42.7227C41.5743 44.1741 41.5515 45.7964 41.9459 47.5896L43.1286 50.8391L31.5845 55.0408L30.1839 51.1928C29.4627 48.6792 29.1718 46.4609 29.3112 44.5379C29.5076 42.5942 29.9114 40.8655 30.5225 39.3519C31.1129 37.7812 31.8226 36.3608 32.6515 35.0907C33.4805 33.8206 34.2239 32.5817 34.8818 31.3738C35.5397 30.1659 35.9669 28.9452 36.1635 27.7116C36.4171 26.4572 36.2353 25.0707 35.618 23.5523C34.6636 20.9299 33.3025 19.2302 31.5348 18.4533C29.8242 17.6556 27.8287 17.6718 25.5484 18.5017C24.0092 19.0619 22.7836 19.8631 21.8717 20.9052C20.996 21.8695 20.3744 22.9996 20.0068 24.2955C19.6754 25.5136 19.5877 26.869 19.7436 28.3617C19.8788 29.7974 20.2265 31.2848 20.7867 32.824L8.21647 37.3992Z" fill="#0F4C41"/>
                </svg>
                </span>
            }
        >
            <ol className='placebet-content'>
                <li>Total token on Monsta Launchpad: 8,000,000 $LOA</li>
                <li>Purchase with: $STT</li>
                <li>Vesting Period: 200 days (Daily Unlock) </li>
                <li>Only 1 tier applicable for each wallet address</li>
            </ol>
        </MonstaModal>
    );
};

export default PlaceBetModal;