import { Button } from "antd";
import "./style.less";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useMonstaAuth } from "../../hooks/useMonstaAuth";
import {
  formatFixedNumber,
  formatToReadableNumber,
} from "../../utils/formatBigNumber";
import BigNumber from "bignumber.js";
import React, { useEffect } from "react";

const TierCard = ({
  tierData,
  xsttAllowance,
  xmoniAllowance,
  isXmoniApproving,
  isXsttApproving,
  isStaking,
  isPurchasing,
  xsttToPay,
  stakedAmount,
  paidAmount,
  stakingStarted,
  saleStarted,
  handleXmoniApprove,
  handleXsttApprove,
  handleStake,
  handlePurchase,
  updateActiveTier,
}) => {
  const { account } = useActiveWeb3React();
  const { connect } = useMonstaAuth();
  const isActive = stakedAmount === "0";
  const isStaked = stakedAmount === tierData.stakeAmount;
  const isPaid = paidAmount !== "0" && paidAmount === xsttToPay;

  useEffect(() => {
    if (isStaked) updateActiveTier(tierData.tier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStaked]);

  return (
    <div
      className="tier-card"
      style={{
        filter: isActive || isStaked ? "" : "brightness(20%)",
      }}
    >
      <div className="card-header">
        <img src={tierData.headerAvatar} alt="" className="card-avatar" />
        <div className="card-title">{tierData.headerTitle}</div>
      </div>
      <div className="card-body">
        <div className="row">
          <span>Token for Sale</span>
          <span>{formatToReadableNumber(tierData.tokenForSale)} $LOA</span>
        </div>
        <div className="row">
          <span>Staking</span>
          <span>
            {formatToReadableNumber(formatFixedNumber(tierData.stakeAmount, 0))}{" "}
            MONI
          </span>
        </div>
        <div className="row">
          <span>Vesting Period</span>
          <span>2 Months</span>
        </div>
        <div className="row">
          <span>Participants</span>
          <span>{tierData.participantAmount} Wallet Address</span>
        </div>
        <div className="row">
          <span>Eligible for</span>
          <span>
            <strong>{tierData.eligibleAmount} $LOA per</strong>{" "}
            <p>Wallet Address</p>
          </span>
        </div>
        <div className="row">
          <span>xSTT Needed</span>
          <span>
            <strong>{tierData.xsttNeeded}</strong>{" "}
          </span>
        </div>

        {!account ? (
          <Button block size="large" onClick={connect}>
            Connect Wallet
          </Button>
        ) : new BigNumber(xmoniAllowance).lt(
            new BigNumber(tierData.stakeAmount)
          ) && !isStaked ? (
          <Button block size="large" disabled={true}>
            Stake Ended
          </Button>
        ) : // TODO : above code is a hack to disable stake since smart contract does not have end stake function
        // <Button
        //   block
        //   size="large"
        //   onClick={() =>
        //     handleXmoniApprove(tierData.stakeAmount, tierData.tier)
        //   }
        //   disabled={!isActive || !stakingStarted}
        //   loading={isXmoniApproving}
        // >
        //   {stakingStarted ? 'Approve xMONI' : 'Stake Not Started'}
        // </Button>
        new BigNumber(xsttAllowance).lt(new BigNumber(xsttToPay)) &&
          !isPaid &&
          isStaked ? (
          <Button block size="large" disabled={true}>
            Sale Ended
          </Button>
        ) : // <Button
        //   block
        //   size="large"
        //   onClick={() => handleXsttApprove(xsttToPay, tierData.tier)}
        //   loading={isXsttApproving}
        // >
        //   Approve xSTT
        // </Button>
        !isPaid && isStaked ? (
          <Button block size="large" disabled={true}>
            Sale Ended
          </Button>
        ) : (
          // <Button
          //   block
          //   size="large"
          //   onClick={() => handlePurchase(tierData.tier)}
          //   loading={isPurchasing}
          //   disabled={!saleStarted}
          // >
          //   {saleStarted ? "Purchase" : "Sale Not Started"}
          // </Button>
          !isStaked && (
            <Button block size="large" disabled={true}>
              Stake Ended
            </Button>
            // TODO : above code is a hack to disable stake since smart contract does not have end stake function
            // <Button
            //   block
            //   size="large"
            //   onClick={() => handleStake(tierData.tier)}
            //   disabled={!isActive || !stakingStarted}
            //   loading={isStaking}
            // >
            //   {stakingStarted ? "Stake" : "Staking not started"}
            // </Button>
          )
        )}
        {isStaked && isPaid && account ? (
          <Button block size="large" disabled>
            Paid
          </Button>
        ) : (
          isStaked &&
          !isPaid &&
          account && (
            <Button block size="large" disabled>
              Staked
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default TierCard;
