import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { monstaConnector, setupNetwork } from "../services/web3";

export function useMonstaAuth(): {
  account: typeof account;
  active: typeof active;
  connect: typeof connect;
  disconnect: typeof disconnect;
  refresh: typeof refresh;
} {
  const { account, activate, deactivate, active, error } = useWeb3React();

  async function refresh() {
    try {
      const isAuthorized = await monstaConnector.isAuthorized();
      if (isAuthorized && !active && !error) {
        await activate(monstaConnector);
        await connect();
      }
    } catch (error) {
      console.log("Injected error : ", error);
    }
  }

  async function connect() {
    try {
      await activate(monstaConnector, undefined, true);
    } catch (err) {
      if (err instanceof UnsupportedChainIdError) {
        const hasSetup = await setupNetwork();
        if (hasSetup) {
          await activate(monstaConnector, undefined, true);
        } else {
          console.error(err);
        }
      } else {
        console.error(err);
      }
    }
  }

  async function disconnect() {
    try {
      deactivate();
    } catch (err) {
      console.error(err);
    }
  }

  return { account, active, connect, disconnect, refresh };
}
