import fetchGraphQL from "./fetchGraphQL";

type ResponseType = {
  id: string;
  amount: string;
  holder: string;
}

const fetchStake = async (holder: string, first: number = 1): Promise<ResponseType | null> => {
  try {
    const response = await fetchGraphQL(
      "launchpad",
      `
      query Stakes($first: Int, $holder: BigInt!) {
        stakes(
          first: $first, 
          where: { holder: $holder }
        ) {
          id
          amount
          holder
        }
      }
      `,
      {
        holder,
        first,
      }
    );

    return response.data.stakes[0];
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default fetchStake;
