import BigNumber from "bignumber.js";

import fetchGraphQL from "./fetchGraphQL";

const fetchAllowance = async (token?: string, owner?: string, spender?: string): Promise<any> => {
  try {
    if (!owner || !spender) {
      return;
    }
    const response = await fetchGraphQL(
      "erc20",
      `
      query ApprovalQuery($id: ID!){
        erc20Approval (id: $id) {
          valueExact
        }
      }
      `,
      {
        id: token.toLowerCase() + "/" + owner.toLowerCase() + "/" + spender.toLowerCase(),
      }
    );
    if (response.data && response.data.erc20Approval) {
      return new BigNumber(response.data.erc20Approval.valueExact);
    } else {
      return new BigNumber(0);
    }
  } catch (error) {
    console.error(error);
  }
};


export default fetchAllowance;