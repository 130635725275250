// ABI
import { ethers } from "ethers";
import moniStakingAbi from "../config/abi/moniStaking.json";
import sttCollectorAbi from "../config/abi/sttCollector.json";
import bep20Abi from "../config/abi/bep20.json";
import {
    getMoniStakingAddress,
    getSttCollectorAddress,
    getMoniAddress,
    getSTTAddress
} from "./addressHelpers";
import { simpleRpcProvider } from "./providers";

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getMoniContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, getMoniAddress(), signer);
};

export const getSttContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, getSTTAddress(), signer);
};

export const getMoniStakingContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(moniStakingAbi, getMoniStakingAddress(), signer);
};
export const getSttCollectorContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(sttCollectorAbi, getSttCollectorAddress(), signer);
};
