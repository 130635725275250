import { Layout, Button, message } from "antd";
import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import imgHeaderLogo from "../../assets/images/Asset_Header_Logo.png";
import uncommonAvatar from "../../assets/images/uncommon-icon.png";
import rareAvatar from "../../assets/images/rare-icon.png";
import imgBg from "../../assets/images/bg-LOA.jpg";
import immortalAvatar from "../../assets/images/immortal-icon.png";
import legendaryAvatar from "../../assets/images/legendary-icon.png";
import "./style.less";
import PlaceBetModal from "../../components/PlaceBetModal";
import TierCard from "../../components/TierCard";
import Counter from "../../components/Counter";
import useRefresh from "../../hooks/useRefresh";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import fetchAllowance from "../../utils/fetchAllowance";
import fetchStake from "../../utils/fetchStake";
import fetchPayment from "../../utils/fetchPayment";
import {
  getMoniStakingContract,
  getMoniContract,
  getSttContract,
  getSttCollectorContract,
} from "../../utils/contractHelpers";
import {
  getMoniStakingAddress,
  getSttCollectorAddress,
  getMoniAddress,
  getSTTAddress,
} from "../../utils/addressHelpers";
import { Contract } from "@ethersproject/contracts";
import BigNumber from "bignumber.js";

const { Content } = Layout;
const tiers = [
  {
    tier: 4,
    tokenForSale: 1500000,
    stakeAmount: "100000000000000000000",
    participantAmount: 5000,
    eligibleAmount: 300,
    headerTitle: "Troposphere",
    headerAvatar: uncommonAvatar,
    xsttNeeded: "3,600",
  },
  {
    tier: 3,
    tokenForSale: 1500000,
    stakeAmount: "500000000000000000000",
    participantAmount: 1000,
    eligibleAmount: 1500,
    headerTitle: "Stratosphere",
    headerAvatar: rareAvatar,
    xsttNeeded: "17,411",
  },
  {
    tier: 2,
    tokenForSale: 2000000,
    stakeAmount: "2000000000000000000000",
    participantAmount: 250,
    eligibleAmount: 8000,
    headerTitle: "Thermosphere",
    headerAvatar: legendaryAvatar,
    xsttNeeded: "93,023",
  },
  {
    tier: 1,
    tokenForSale: 3000000,
    stakeAmount: "10000000000000000000000",
    participantAmount: 50,
    eligibleAmount: 60000,
    headerTitle: "Exosphere",
    headerAvatar: immortalAvatar,
    xsttNeeded: "697,674",
  },
];
const Main = () => {
  const [isBetModal, setIsBetModal] = useState(false);
  const { account, library } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();
  const [xmoniAllowance, setXmoniAllowance] = useState(0);
  const [xsttAllowance, setXsttAllowance] = useState(0);
  const [isXmoniApproving, setIsXmoniApproving] = useState(false);
  const [isXsttApproving, setIsXsttApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [activeTier, setActiveTier] = useState(0);
  const [stakedAmount, setStakedAmount] = useState("0");
  const [paidAmount, setPaidAmount] = useState("0");
  const [xsttToPay, setXsttToPay] = useState("0");
  const [isStakingStarted, setIsStakingStarted] = useState(false);
  const [isSaleStarted, setIsSaleStarted] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);

  // Fetch allowance and deposit amount for STAKING when switching account
  useEffect(() => {
    const fetchData = async () => {
      const allowance = await fetchAllowance(
        getMoniAddress(),
        account,
        getMoniStakingAddress()
      );
      setXmoniAllowance(allowance.toFixed());

      const stake = await fetchStake(account);
      const stakeAmount = stake?.amount || "0";
      setStakedAmount(stakeAmount);
    };
    if (account && isStakingStarted) {
      fetchData();
    }
  }, [account, isStakingStarted]);

  // Fetch allowance, amountToCollect and paidAmount for SALE when switching account
  useEffect(() => {
    const fetchData = async () => {
      const allowance = await fetchAllowance(
        getSTTAddress(),
        account,
        getSttCollectorAddress()
      );
      setXsttAllowance(allowance.toString());
   
      const sttCollectorContract: Contract | null = getSttCollectorContract();
      const sttToPay = await sttCollectorContract.amountToCollect(account);
      setXsttToPay(sttToPay.toString());
      
      const payment = await fetchPayment(account)
      const paidAmount = payment?.amount || "0";
      if (paidAmount === sttToPay.toString() && sttToPay.toString() !== "0")
        setIsPurchased(true)
      else
        setIsPurchased(false)
      setPaidAmount(paidAmount);
    };
    if (account && isSaleStarted) {
      fetchData();
    }
  }, [account, isSaleStarted]);

  const handleXmoniApprove = (stakeAmount, tier) => {
    if (account && library) {
      const moniContract: Contract | null = getMoniContract(
        library.getSigner()
      );
      setIsXmoniApproving(true);
      moniContract
        .approve(getMoniStakingAddress(), stakeAmount)
        .then(() => {
          setActiveTier(tier);
        })
        .catch((err) => {
          setIsXmoniApproving(false);
          console.log(err);
        });
    }
  };

  const handleXsttApprove = (xsttToPay, tier) => {
    if (account && library) {
      const sttContract: Contract | null = getSttContract(library.getSigner());
      setIsXsttApproving(true);
      sttContract
        .approve(getSttCollectorAddress(), xsttToPay)
        .then(() => {
          setActiveTier(tier);
        })
        .catch((err) => {
          setIsXsttApproving(false);
          console.log(err);
        });
    }
  };

  const handleStake = (tier) => {
    if (!isStakingStarted) {
      message.error("Staking not started yet");
      return;
    }
    setActiveTier(tier);
    if (account && library) {
      const stakingContract: Contract | null = getMoniStakingContract(
        library.getSigner()
      );
      setIsStaking(true);
      stakingContract.estimateGas[`stakeT${tier}`]()
        .then((res) => {
          switch (tier) {
            // TODO : duplicated code, clean up later
            case 1:
              stakingContract
                .stakeT1()
                .then(() => setIsStaking(true))
                .catch(() => setIsStaking(false));
              break;
            case 2:
              stakingContract
                .stakeT2()
                .then(() => setIsStaking(true))
                .catch(() => setIsStaking(false));
              break;
            case 3:
              stakingContract
                .stakeT3()
                .then(() => setIsStaking(true))
                .catch(() => setIsStaking(false));
              break;
            case 4:
              stakingContract
                .stakeT4()
                .then(() => setIsStaking(true))
                .catch(() => setIsStaking(false));
              break;
            default:
              message.error(`Unsuccessful, please try again later`);
              break;
          }
        })
        .catch((error: any) => {
          setIsStaking(false);
          if (error?.code === 4001) {
            message.error("Transaction rejected.");
          } else if (error?.code === -32603) {
            switch (error.data.message) {
              case "execution reverted: MoniStaking: not whitelisted":
                message.error("Your wallet is not whitelisted");
                break;
              case "execution reverted: MoniStaking: already staked":
                message.error("Already staked");
                break;
              case "execution reverted: MoniStaking: no slots left":
                message.error("No slot left")
                break;
              default:
                message.error(`${error.data.message}`);
            }
          } else {
            console.log(error);
            message.error(`Unsuccessful, please try again later`);
          }
        });
    }
  };

  const handlePurchase = (tier) => {
    if (!isSaleStarted) {
      message.error("Sale not started yet");
      return;
    }
    setActiveTier(tier);
    const contract: Contract | null = getSttCollectorContract(
      library.getSigner()
    );
    setIsPurchasing(true);
    contract.estimateGas["makePayment"]()
      .then((res) => {
        contract.makePayment().catch(() => {
          setIsPurchasing(false);
        });
      })
      .catch((error: any) => {
        setIsPurchasing(false);
        if (error?.code === 4001) {
          message.error("Transaction rejected.");
        } else if (error?.code === -32603) {
          switch (error.data.message) {
            case "execution reverted: SttCollector: not eligible":
              message.error("Sale not started yet"); // TODO : if timer ended : not eligible to purchase, else : staking not started yet
              break;
            case "execution reverted: ERC20: transfer amount exceeds allowance":
              message.error("Purchase amount exceed approve amount");
              break;
            default:
              message.error(`${error.data.message}`);
          }
        } else {
          console.log(error);
          message.error(`Unsuccessful, please try again later`);
        }
      });
  };

  const updateActiveTier = (tier) => {
    setActiveTier(tier);
  }

  // When pending xmoni approval
  useEffect(() => {
    if (isXmoniApproving && account && activeTier) {
      fetchAllowance(getMoniAddress(), account, getMoniStakingAddress())
        .then((value) => {
          if (value.gte(new BigNumber(tiers.find((x) => x.tier === activeTier).stakeAmount))) {
            setXmoniAllowance(value.toString());
            setIsXmoniApproving(false);
          }
        })
        .catch(() => setIsXmoniApproving(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fastRefresh, account, isXmoniApproving, activeTier]);

  // When pending xstt approval
  useEffect(() => {
    if (isXsttApproving && account && xsttToPay) {
      fetchAllowance(getSTTAddress(), account, getSttCollectorAddress())
        .then((value) => {
          if (value.gte(new BigNumber(xsttToPay))) {
            setXsttAllowance(value.toString());
            setIsXsttApproving(false);
          }
        })
        .catch(() => setIsXsttApproving(false));
    }
  }, [fastRefresh, account, isXsttApproving, xsttToPay]);

  // When pending stake
  useEffect(() => {
    if (isStaking && account) {
      const stakingContract: Contract | null = getMoniStakingContract(
        library.getSigner()
      );
      // TODO : use subgraph instead once ready
      stakingContract.depositAmount(account).then((stakeAmount) => {
        if (
          stakeAmount.toString() >=
          tiers.find((x) => x.tier === activeTier).stakeAmount
        ) {
          setStakedAmount(stakeAmount.toString());
          setIsStaking(false);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fastRefresh, account, isStaking, library, activeTier]);

  // When pending purchase
  useEffect(() => {
    if (isPurchasing && account) {
      const sttCollectorContract: Contract | null = getSttCollectorContract();
      // TODO: use subgraph instead once ready
      sttCollectorContract.paidAmount(account).then((paidAmount) => {
        if (paidAmount.toString() >= xsttToPay && xsttToPay !== "0") {
          setPaidAmount(paidAmount.toString());
          setIsPurchasing(false);
          setIsPurchased(true);
        } else {
          setIsPurchased(false);
        }
      });
    }
  }, [fastRefresh, account, isPurchasing, xsttToPay]);

  return (
    <>
      <Header purchased={isPurchased} amount={activeTier ? tiers.find((x) => x.tier === activeTier).eligibleAmount : 0} />
      <Content className="content">
        <div className="hero">
          <img className={"bg " + (isPurchased ? "purchase-alert" : "")} src={imgBg} alt="asset-header" />
          <img className="logo" src={imgHeaderLogo} alt="asset-header" />
          <h1>League of Ancients</h1>
          <h3>
            League of Ancients (LOA) is a ESPORT MOBA NFT-game inspired by DOTA2
            and League of Legends. The first ever free to play and play to earn
            MOBA game with NFT Marketplace allowing players to stake and earn
            token using their NFT skins.
          </h3>
          <Button size="large" onClick={() => setIsBetModal(!isBetModal)}>
            View Details
          </Button>
        </div>
        <div className="counter-wrapper">
          <Counter
            destDate={Math.floor(
              new Date("12/11/2021 00:00:00 UTC").getTime() / 1000
            )}
            onEnd={() => setIsStakingStarted(true)}
            isSale={false}
          />
          <Counter
            destDate={Math.floor(
              new Date("12/11/2021 12:00:00 UTC").getTime() / 1000
            )}
            onEnd={() => setIsSaleStarted(true)}
            isSale={true}
          />
        </div>
        <div className="card-wrapper">
          {tiers.map((data) => {
            return (
              <TierCard
                tierData={data}
                key={data.tier}
                xsttAllowance={xsttAllowance}
                xmoniAllowance={xmoniAllowance}
                isXmoniApproving={isXmoniApproving}
                isXsttApproving={isXsttApproving}
                isStaking={isStaking}
                isPurchasing={isPurchasing}
                xsttToPay={xsttToPay}
                stakedAmount={stakedAmount}
                paidAmount={paidAmount}
                stakingStarted={isStakingStarted}
                saleStarted={isSaleStarted}
                handleXmoniApprove={handleXmoniApprove}
                handleXsttApprove={handleXsttApprove}
                handleStake={handleStake}
                handlePurchase={handlePurchase}
                updateActiveTier={(tier) => updateActiveTier(tier)}
              />
            );
          })}
        </div>
      </Content>
      <PlaceBetModal
        title="How to?"
        visible={isBetModal}
        onCancel={() => setIsBetModal(false)}
      />
    </>
  );
};

export default Main;
