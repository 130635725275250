import { Modal } from 'antd';
import React from 'react';
import './style.less';

export type ModalProps = {
    title: string;
    visible: boolean;
    onOk?: () => void;
    onCancel: () => void;
    noFooter?: boolean;
    icon?: any;
}

const MonstaModal: React.FC<ModalProps> = ({icon, title, visible, onOk=null, onCancel, children, noFooter=true}) => {
    return (
        <Modal className={noFooter? 'noFooter' : ''} title={title} visible={visible} onOk={onOk ? onOk : () => {}} onCancel={onCancel}>
            {children}
            {icon && 
                <div className='type-icon'>
                    {icon}
                </div>
            }
        </Modal>
    );
};

export default MonstaModal;